<template>
    <div class="content">
        <div>
            用户隐私协议
        </div>
        <div class="privacyPolicyCont"> 尊敬的用户： <br>您好！ <br>根据《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在移动公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话卡（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名、证件类型、证件号码等。同时，为更好地提供服务，可能需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信这类业务办理服务时，我们可能需要收集和使用您的手机号码、短信验证码，确保成功为您办理业务。 <br>为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息： <br>1）事先获得客户的明确授权； <br>2）根据有关的法律法规要求； <br>3）按照相关司法机关和/或政府主管部门的要求； <br>4）为维护社会公众的利益所必需且适当； <br>5）为维护我公司或客户的合法权益所必需且适当； </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.content{
    font-size: 0.34rem;
    padding: 0.2rem;
    letter-spacing: 0.01rem;
    word-break: break-all;
    p{
        font-size: 0.37rem;
        font-weight: bold;
    }
}
</style>